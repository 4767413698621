import * as actionTypes from '../../store/actions/actionTypes'
import api from './Api'
import userApi from './userApi'
import * as APIConstants from './Apiconstants'
import * as wexerAPIConstants from '../../store/APIConstants'
import { ConsentType, SSO_TOKEN_EXPIRE_BUFFER_SEC } from '../../utils/constants'
import { Translate } from '../../utils/Translate'
import logger from "../../utils/logger"
import axios from 'axios';
import environment from "./config";
import { batch } from 'react-redux'
import * as openId from '@openid/appauth'
import moment from 'moment'
import { includes } from 'lodash'
import { isEmbeddedMode } from '../../utils/utilityfunctions'
const URL = environment.BASE_URL

//By Nikhil on 25th Nov, 2019
//Replicated the code of AuthenticateWexerUser as we need to hit the wexer server for authentication
//in future, we can have different authentication server for the tenant
// export const AuthenticateThirdPartyUser = (uname, pwd) => {
//   return dispatch => {
//     dispatch(AuthenticateWexerUser(uname, pwd))
//   }
// }
const clientID = process.env.REACT_APP_API_CLIENT_ID
const clientSecret = process.env.REACT_APP_API_CLIENT_SECRET
const signature = clientID + ':' + clientSecret
const base64Signature = btoa(signature)
const Authorization = `Basic ${base64Signature}`;

export const createThirdPartyUser = (data) => {

  return dispatch => {
    let barcodeId = data.UserDetail.ApplicationProfile.barcodeId
    let email = data.UserDetail.ApplicationProfile.email
    let password = data.UserDetail.ApplicationProfile.password;

    let payLoad = {
      "userDetail": {
        "applicationProfile": {
          "emailAddress": email,
          "passwordHash": password,
          "countryCode": "gb"
        }
      },
      "emailPassword": false,
      "thirdPartyUserId": barcodeId,
      "skipConsent": true,
      //"ConsentRequest": data.ConsentRequest
    }
    dispatch({ type: actionTypes.USER_SIGNUP_START });
    api.setHeader('Authorization', Authorization)
    api.setHeader('TenantId', localStorage.getItem('clientId'))

    api.post(wexerAPIConstants.creatThirdParyUserOnWexerAPI, payLoad).then(response => {
      if (response.ok) {
        dispatch({ type: actionTypes.USER_SIGNUP_SUCCESS });
        dispatch({
          type: actionTypes.SHOW_PRESET_NOTIFICATION
        });

        dispatch(AuthenticateThirdPartyUser({
          barcodeId: barcodeId,
          email: email,
          password: password
        }));
      } else {
        let errorMsg = "";
        if (response.status === 400) {
          errorMsg = Translate({ id: "ApiResponseStrings.InvalidDataPassed" });

          dispatch({
            type: actionTypes.NOTIFY_USER,
            NotificationText: Translate({
              id: "ApiResponseStrings.InvalidDataPassed"
            }),
            NotificationType: 'error'
          });
        } else if (response.status === 409) {
          errorMsg = Translate({ id: "ApiResponseStrings.EmailaddressInUse" });
          dispatch({
            type: actionTypes.NOTIFY_USER,
            NotificationText: Translate({
              id: "ApiResponseStrings.EmailaddressInUse"
            }),
            NotificationType: 'error'
          });
        } else {
          errorMsg = Translate({
            id: "ApiResponseStrings.InternalServerError"
          });
          dispatch({
            type: actionTypes.NOTIFY_USER,
            NotificationText: "Something went wrong !",
            NotificationType: 'error'
          });
        }
        dispatch({
          type: actionTypes.USER_SIGNUP_FAIL,
          errorMsg: errorMsg
        });

        dispatch({
          type: actionTypes.NOTIFY_USER,
          NotificationText: errorMsg,
          NotificationType: 'error'
        });
      }
    });
  }
};

export const AuthenticateThirdPartyUser = (userData) => {
  return dispatch => {
    const loginApiStartTime = new Date().getTime();
    const { token } = userData;
    
    dispatch({ type: actionTypes.AUTH_START });
    var bodyFormData = new FormData()
    bodyFormData.append('client_id', process.env.REACT_APP_API_CLIENT_ID)
    bodyFormData.append('redirect_uri', process.env.REACT_APP_API_BASE_URL)
    bodyFormData.append('response_type', 'token')
    bodyFormData.append('scope', 'openid')
    bodyFormData.append('tenantId', localStorage.getItem('clientId'))
    bodyFormData.append('token', token);
    bodyFormData.append('skipConsent', true);
    // bodyFormData.append('countryCode', 'gb');

    api.setHeader('Content-Type', 'multipart/form-data')
    //api.deleteHeader('Authorization')
    //api.deleteHeader('TenantId')

    api.post(APIConstants.LoginAPI, bodyFormData).then(response => {
      logger(response);
      const loginApiEndTime = new Date().getTime();
      const loginApiTotleTime =Math.abs((loginApiStartTime - loginApiEndTime) / 1000);
      dispatch({
        type: actionTypes.LOAD_EVENT,
        payLoad:{
          externalLoginResponseTime: response.data?.ExternalLoginResponseTime,
          userId: response.data?.UserId,
          loginAPIResponseTime: loginApiTotleTime,
          isUserLoaded: true,
          errorMessage:response?.originalError?.message ?? ''
        }
      });
      if (response.status === 200) {
        localStorage.setItem('token', response.data.Code)
        localStorage.setItem('userId', response.data.UserId)
        localStorage.setItem('ExternalUserId', response.data.ExternalUserId)
        batch(()=>{
          dispatch({
            type: actionTypes.AUTH_SUCCESS,
            token: response.data.Code,
            userId: response.data.UserId,
          })
          dispatch({
            type: actionTypes.SHOW_ERROR_PAGE_SSO,
            showErrorPageSSO: false
          });
        });
      }
      else if (response.status === 201) {
        localStorage.setItem('token', response.data.UserDetail.Code)
        localStorage.setItem('userId', response.data.UserDetail.UserId)
        localStorage.setItem('ExternalUserId', response.data.ExternalUserId)
        dispatch({
          type: actionTypes.AUTH_SUCCESS,
          token: response.data.Code,
          userId: response.data.UserId,
        })
        dispatch({
          type: actionTypes.SHOW_ERROR_PAGE_SSO,
          showErrorPageSSO: false
        });

      }
      else {
        if (response.status === 404) {
          dispatch({
            type: actionTypes.AUTH_FAIL,
            error: Translate({
              id: 'ApiResponseStrings.NoAccessResponseError'
            })
          })
          dispatch({
            type: actionTypes.SHOW_ERROR_PAGE_SSO,
            showErrorPageSSO: true
          });
        } else if (response.status === 500) {
          dispatch({
            type: actionTypes.AUTH_FAIL,
            error: Translate({ id: 'ApiResponseStrings.InternalServerError' })
          })
          dispatch({
            type: actionTypes.SHOW_ERROR_PAGE_SSO,
            showErrorPageSSO: true
          });
        } else if (response.status === 501) {
          dispatch({
            type: actionTypes.AUTH_FAIL,
            error: Translate({ id: 'ApiResponseStrings.InvalidTokenError' })
          })
          dispatch({
            type: actionTypes.SHOW_ERROR_PAGE_SSO,
            showErrorPageSSO: true
          });
        }
        else {
          dispatch({
            type: actionTypes.AUTH_FAIL,
            error: response.originalError.message
          })

          dispatch({
            type: actionTypes.SHOW_ERROR_PAGE_SSO,
            showErrorPageSSO: true
          });
        }
      }
    })
  };
};
export const GetConsentDetail = () => {
  return dispatch => {
    api.get(wexerAPIConstants.consentAPI).then(response => {
      if (response.ok) {
        dispatch({
          type: actionTypes.FETCH_CONSENT_SUCCESS,
          consents: response.data
        })
      }
    })
  }
}
const isTokenExpire = () => {
  const expiresIn = new Date(localStorage.getItem("expiresIn"));
  const now = new Date();
  return now.getTime() >= expiresIn.getTime()
}
const getNewToken =()=>{
  const { AuthorizationServiceConfiguration, BaseTokenRequestHandler, FetchRequestor, GRANT_TYPE_REFRESH_TOKEN, TokenRequest } = openId;
  return new Promise((resolve)=>{
      // if(isTokenExpire()){
          const refresh_token = localStorage.getItem("refreshToken");
          const client_id = localStorage.getItem("ssoClientId");
          
          const tokenRequest = new TokenRequest({
              client_id,
              grant_type: GRANT_TYPE_REFRESH_TOKEN,
              refresh_token,
          });
          const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor());
          AuthorizationServiceConfiguration.fetchFromIssuer(environment.SSO.OPServer, new FetchRequestor())
                          .then((oResponse) => {
                              const configuration = oResponse;
                              return tokenHandler.performTokenRequest(configuration, tokenRequest);
                          }).then((res)=>{
                              setSsoTokenData(res);
                              resolve({status:true,res})
                          }).catch((oError) => {
                              resolve({status:false,err:oError})
                          });
      // }
      // else{
      //     resolve({status:false,err:"TOKEN IS NOT EXPIRED"})
      // }
  });
}
const setSsoTokenData = (res)=>{
  const expireTokenTime = moment().add(res.expiresIn - SSO_TOKEN_EXPIRE_BUFFER_SEC, 'seconds');
  localStorage.setItem("expiresIn",expireTokenTime.toString());
  localStorage.setItem("refreshToken",res.refreshToken);
  localStorage.setItem("SSOToken", res.accessToken);
}
//By Nikhil on 26h Nov, 2019
//This method is used to get the user contracts from the third party server.

export const getThirdPartyUserSubscription = () => {

  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_USER_SUBSCRIPTION_START
    });
    // check if appplication is not running in EmbeddedMode and TokenExpire
    if(isTokenExpire() && !isEmbeddedMode()){
      getNewToken().then((res)=>{
        if(res.status === true){
          dispatch(AuthenticateThirdPartyUser({token:res.res.accessToken}));
          const SSOToken = localStorage.getItem("SSOToken")
          axios.get(URL + APIConstants.getUserSubscriptionAPI, {
            headers: {
              'Authorization': Authorization,
              'content-type': 'application/json',
              'TenantId': window.localStorage.getItem('clientId'),
              'X-EXTERNAL-IDENTIFIER': SSOToken
            }
          }).then(response => {
            if (response.status === 200) {
              if (response.data.length > 0) {

                if (response.data[0].Status && response.data[0].Status.toLowerCase() === 'active') {
                  dispatch({
                    type: actionTypes.FETCH_USER_SUBSCRIPTION_SUCCESS,
                    status: true,
                  });
                  dispatch(GetUserDetail())
                }
                else {
                  logger(`userSusbcription error ${response}`)
                  dispatch({
                    type: actionTypes.FETCH_USER_SUBSCRIPTION_FAIL,
                    error: Translate({ id: 'ApiResponseStrings.InvalidTokenError' })
                  })
                }

              }
            } else {
              if (response.status === 404) {
                dispatch({
                  type: actionTypes.FETCH_USER_SUBSCRIPTION_FAIL,
                  error: Translate({
                    id: 'ApiResponseStrings.NoAccessResponseError'
                  })
                })
              } else if (response.status === 500) {
                dispatch({
                  type: actionTypes.FETCH_USER_SUBSCRIPTION_FAIL,
                  error: Translate({ id: 'ApiResponseStrings.InternalServerError' })
                })
              
              } else if (response.status === 501) {
                dispatch({
                  type: actionTypes.FETCH_USER_SUBSCRIPTION_FAIL,
                  error: Translate({ id: 'ApiResponseStrings.InvalidTokenError' })
                })
              
              }
              else {
                dispatch({
                  type: actionTypes.FETCH_USER_SUBSCRIPTION_FAIL,
                  error: response.originalError.message
                })
              }
            }
          }).catch(function (er) {
            logger(`userSusbcription catch ${er}`)
            dispatch({
              type: actionTypes.FETCH_USER_SUBSCRIPTION_END,
              status: true,
            });
          });
        }
        else{
          batch(()=>{
            dispatch({
              type: actionTypes.FETCH_USER_SUBSCRIPTION_END,
            });
            dispatch({
              type:actionTypes.FETCH_USER_TOKEN_ERROR
            })
          })
        }
      }).catch(function (er) {
        console.log(`userSusbcription catch ${er}`)
        batch(()=>{
          dispatch({
            type: actionTypes.FETCH_USER_SUBSCRIPTION_END,
          });
          dispatch({
            type:actionTypes.FETCH_USER_TOKEN_ERROR
          })
        })
      });
    }
    else{
    
      // not handel any catch state.
      const SSOToken = localStorage.getItem("SSOToken")
      
      dispatch({
        type: actionTypes.FETCH_USER_SUBSCRIPTION_START
      });
      axios.get(URL + APIConstants.getUserSubscriptionAPI, {
        headers: {
          'Authorization': Authorization,
          'content-type': 'application/json',
          'TenantId': window.localStorage.getItem('clientId'),
          'X-EXTERNAL-IDENTIFIER': SSOToken
        }
      }).then(response => {
        if (response.status === 200) {
          if (response.data.length > 0) {

            if (response.data[0].Status && response.data[0].Status.toLowerCase() === 'active') {
              dispatch({
                type: actionTypes.FETCH_USER_SUBSCRIPTION_SUCCESS,
                status: true,
              });
              dispatch(GetUserDetail())
            }
            else {
              logger(`userSusbcription error ${response}`)
              dispatch({
                type: actionTypes.FETCH_USER_SUBSCRIPTION_FAIL,
                error: Translate({ id: 'ApiResponseStrings.InvalidTokenError' })
              })
            }

          }
        } else {
          if (response.status === 404) {
            dispatch({
              type: actionTypes.FETCH_USER_SUBSCRIPTION_FAIL,
              error: Translate({
                id: 'ApiResponseStrings.NoAccessResponseError'
              })
            })
          } else if (response.status === 500) {
            dispatch({
              type: actionTypes.FETCH_USER_SUBSCRIPTION_FAIL,
              error: Translate({ id: 'ApiResponseStrings.InternalServerError' })
            })
          
          } else if (response.status === 501) {
            dispatch({
              type: actionTypes.FETCH_USER_SUBSCRIPTION_FAIL,
              error: Translate({ id: 'ApiResponseStrings.InvalidTokenError' })
            })
          
          }
          else {
            dispatch({
              type: actionTypes.FETCH_USER_SUBSCRIPTION_FAIL,
              error: response.originalError.message
            })
          }
        }
    }).catch(function (er) {
      /* 
         dispatch({
          type: actionTypes.FETCH_USER_SUBSCRIPTION_END,
          status: true,
        });
      */
      const errMsgs = ['Request failed with status code 501','Request failed with status code 500']
      if(includes(errMsgs,er.message)){
        if(sessionStorage.getItem("embededConfig")){
          window.location.reload();
        }
        else{
          if(window.location.pathname !== '/signin'){
            localStorage.removeItem("token");
            localStorage.removeItem("userId");
            localStorage.removeItem("SSOToken")
            dispatch({ type: actionTypes.AUTH_LOGOUT });
            window.location.assign("/signin?SSO=false&tokenError=true");
          }
        }
      }
      logger(`userSusbcription catch ${er}`)
    });
    }
  };
}

export const UpdateUserProfileData = data => {
  return dispatch => {
    var body = {
      ApplicationProfile: {
        FirstName: data.firstName,
        LastName: data.lastName,
        emailAddress: data.email
      }
    }

    dispatch({
      type: actionTypes.UPDATE_USER_START
    })

    userApi.put(wexerAPIConstants.updateUserDetails, body).then(response => {
      var msg = ''
      let Notification = Translate({
        id: body.ApplicationProfile.FirstName ? 'Profile.ProfileUpdated' : 'Profile.EmailUpdated'
      })
      if (response.status === 200) {
        dispatch(GetUserDetail())
        dispatch({
          type: actionTypes.NOTIFY_USER,
          NotificationText: Notification
        })
        dispatch({
          type: actionTypes.UPDATE_USER_SUCCESS
        })
      } else {
        if (response.status === 409) {
          msg = Translate({ id: 'ApiResponseStrings.EmailaddressInUse' })
        } else {
          msg = Translate({ id: 'ApiResponseStrings.GenericError' })
        }
        dispatch({
          type: actionTypes.UPDATE_USER_FAIL,
          errorMsg: msg
        })
      }
    })
  }
}

export const GetUserConsent = () => {
  return dispatch => {
    // api.setHeader("Authorization", "Bearer");
    userApi.get(wexerAPIConstants.userConsentAPI).then(response => {
      let tncAccepted = false
      if (response.ok) {
        let tncObject = response.data.find(x => x.policy === ConsentType.TNC)
        if (tncObject !== undefined) {
          tncAccepted = tncObject.userAccepted
        }

        dispatch({
          type: actionTypes.FETCH_USER_CONSENT_SUCCESS,
          userConsent: response.data,
          isLatestTncAccepted: tncAccepted
        })
      }
    })
  }
}

export const GetUserDetail = () => {
  return dispatch => {
    // api.setHeader("Authorization", "Bearer");
    dispatch({ type: actionTypes.FETCH_USER_DETAIL_START })
    userApi.get(wexerAPIConstants.getUserDetailAPI).then(response => {
      if (response.ok) {
        dispatch({
          type: actionTypes.FETCH_USER_DETAIL_SUCCESS,
          userDetail: response.data
        })
      } else {
        dispatch({ type: actionTypes.FETCH_USER_DETAIL_FAIL })
      }
    })
  }
}
